import React from "react";
import AOS from 'aos';
import { useEffect } from "react"
import { useLocation } from "react-router-dom";
import "./FormFooter.css"

import Input from '../../FormElementsFooter/InputFooter'
import Button from '../FormElements/Button';
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_EMAIL,
    VALIDATOR_MIN
} from '../../Componentes/util/validators';

import ReCAPTCHA from 'react-google-recaptcha';
import { useReducer, useCallback, useState, useRef } from "react";
import emailService from '../../servicios/email';
import axios from "axios";
import Swal from 'sweetalert2'

const formReducer = (state, action) => {
    switch (action.type) {
        case 'INPUT_CHANGE':
            let formIsValid = true;
            for (const inputId in state.inputs) {
                if (inputId === action.inputId) {
                    formIsValid = formIsValid && action.isValid;
                } else {
                    formIsValid = formIsValid && state.inputs[inputId].isValid;
                }
            }
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.inputId]: { value: action.value, isValid: action.isValid }
                },
                isValid: formIsValid
            };
        default:
            return state;
    }
};



function FormFooter (){

    const [captachValido, cambiarCaptchaValido] = useState(null);
    const [usuarioValido, cambiarUsuarioValido] = useState(false);
    const [envio, setEnvio] = useState(false);
    const [habilitar, setHabilitar] = useState(false);



    const [formState, dispatch] = useReducer(formReducer, {
        inputs: {
           
            Correo: {
                value: '',
                isValid: false
            },
           
            Mensaje: {
                value: '',
                isValid: false
            }
        },
        isValid: false
    });

    const inputHandler = useCallback((id, value, isValid) => {
        dispatch({
            type: 'INPUT_CHANGE',
            value: value,
            isValid: isValid,
            inputId: id
        });
    }, []);


    const captcha = useRef(null);

    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            cambiarCaptchaValido(true);
        };
    }

    const placeSubmitHandler = async(event) => {
        event.preventDefault();
        //console.log(formState.inputs); // send this to the backend!

        const newEmail = {
     
            correo: formState.inputs.Correo.value,
            mensaje: formState.inputs.Mensaje.value,

        }


        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            cambiarUsuarioValido(true);
            cambiarCaptchaValido(true);
         

            if (
                formState.inputs.Correo.isValid &&
                formState.inputs.Mensaje.isValid && captachValido) {

                    const  res = await axios.post(process.env.REACT_APP_BACKEND_URL+"/mensaje", newEmail);
                    setEnvio(true)
                    setHabilitar(true)
                    Swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })

                    setTimeout(() => {
                        setEnvio(false);
                        setHabilitar(false)
                   }, 8000);

            }else{

                Swal.fire({
                    icon: 'error',
                    title: "Llene todo los campos",
                    showConfirmButton: false,
                    timer: 3000
                })
            }

        } else {
            console.log("Por favor acepta el captcha");
            cambiarUsuarioValido(false);
            cambiarCaptchaValido(false);
            setEnvio(false)
            
        }
    };



    return(

        <div>
            <p className="titulo-footer">Contáctanos</p>

            <form onSubmit={placeSubmitHandler}>
                       
                        <Input 
                            id="Correo"
                            element="input"
                            placeholder="Email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Coloque un Email"
                            onInput={inputHandler}
                             className="fondo"
                        />

                    

                        <Input
                            id="Mensaje"
                            placeholder="Deja un mensaje"
                            element="input"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Coloque un mensaje"
                            onInput={inputHandler}
                        />



                        <div className='captcha'>

                            <div>
                                <ReCAPTCHA
                                    ref={captcha}
                                    sitekey='6LfpFm4jAAAAAAgTzpLIie2F4kl08kEdpY98Mana'
                                    onChange={onChange}
                                />
                            </div>


                            <div className='izquierda arriba-1'>
                                <Button type="submit" disabled={habilitar}>
                                    Enviar
                                </Button>
                            </div>

                            {captachValido === false && <div className='error-captcha'> Por favor acepta el captcha</div>}
                            {envio === true && <div className='error-captcha'> Se envió el correo</div>}

                        </div>




                    </form>

        </div>

    )



}



export default FormFooter;