import React from "react";
import AOS from 'aos';
import { useEffect } from "react"
import { useLocation } from "react-router-dom";
import "./Home.css"
import compu from "../../Img/cuartaParte.png"
import grow from "../../Img/Grow.png"
import admin from "../../Img/Admin.png"
import trial from "../../Img/Trial.png"
import pYc from "../../Img/pYc.png"
import clasificacion from "../../Img/clasificacion.png"
import estudioProductoa from "../../Img/estudioProducto.png"
import modelos from "../../Img/modelos.png"
import prediccion from "../../Img/prediccion.png"
import Form from "../../Componentes/Form/Form";

import logoMini from "../../Img/logoMini.png"
import logoBlanco from "../../Img/logoblanco.webp"
import FormFooter from '../../Componentes/FormFooter/FormFooter';


function Home() {


    useEffect(() => {
        AOS.init()
    }, [])

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, [location]);






    return (

        <>
            <div className="container-fluid arriba-header">

                <div id="home" className="row justify-content-center" >

                </div>

                <div className="row justify-content-center  arriba centrar primera">
                    <div className="col-sm-8">
                        <div>
                            <h1>MEJORA TUS
                                DECISIONES DE
                                COMPRAS</h1>

                            <h2>Optimización de inventarios</h2>

                            <div className="centrar">
                                <a href="/#tecnologia">
                                    <i className="fa-solid fa-angle-down icon"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>


                <div id="tecnologia" className="row justify-content-center arriba" >
                    <br />

                </div>


                <div className="row justify-content-center  arriba">
                    <div className="col-sm segunda">
                        <h1>Nuestra Tecnología</h1>
                        <h2>basada en matemática simple de usar</h2>

                    </div>
                </div>


                <div className="row justify-content-center arriba">
                    <div className="col-sm-3 tercera">
                        <h1>01 / Registra</h1>
                        <h2>Agrega todos los productos los cuales  quieras rastrear para recibir alertas de compra.</h2>
                    </div>

                    <div className="col-sm-3 tercera">
                        <h1>02 / Clasifica</h1>
                        <h2>Clasificación ABC que puedes modificarla a tu gusto para recibir las alertas más adecuadas para ti.</h2>
                    </div>


                    <div className="col-sm-3 tercera">
                        <h1>03 / Compra</h1>
                        <h2>Recibe alertas de los productos que están pronto a agotarse en base las políticas pre-diseñadas o las que tu definas.</h2>
                    </div>
                </div>


                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar">
                        <a href="/#trial">
                            <button className="btn btn_1"> <i class="fa-sharp fa-solid fa-diamond"></i> &nbsp; Úsalo en un trial de 30 días</button>
                        </a>
                    </div>

                </div>

                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar">
                        <img src={compu} className="img-fluid" alt="scandere" />
                    </div>

                </div>

                
                <div id="optimizacion" className="row justify-content-center arriba" >
                    <br />

                </div>



                



                <div className="row justify-content-center arriba">

                    <div className="col-sm-3 centrar quinta">
                        <img src={grow} className="img-fluid imagen-1" alt="Grow" />
                        <h1>Clasificación y modelamiento</h1>
                    </div>

                    <div className="col-sm-3 centrar quinta">
                        <img src={trial} className="img-fluid imagen-1" alt="Trial" />
                        <h1>Estudio  de productos</h1>
                    </div>


                    <div className="col-sm-3 centrar quinta">
                        <img src={admin} className="img-fluid imagen-1" alt="Admin" />
                        <h1>Predicción probabilística</h1>
                    </div>


                </div>

                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar cuarta">
                        <h1>La nueva era de optimización de inventario</h1>
                    </div>

                </div>

                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar">
                        <a href="/#trial" >
                            <button className="btn btn_2"> <i class="fa-sharp fa-solid fa-diamond"></i> &nbsp; Úsalo en un trial de 30 días</button>
                        </a>
                    </div>

                </div>



                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar cuarta-1">
                        <h1>Administración científica del inventario</h1>
                    </div>
                </div>


                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar cuarta">
                        <h1>Más Scandere</h1>
                    </div>
                </div>



                {/*---------------------------------*/}


                <div className="row justify-content-center arriba">

                    <div className="col-sm-2 centrado">
                        <img src={pYc} className="img-fluid img-pYc" alt="productos y colecciones" />
                    </div>

                    <div className="col-sm-4 tamanio izquierda">
                        <h1>Productos y Colecciones</h1>
                    </div>

                </div>

                <div className="row justify-content-center arriba">

                    <div className="col-sm-2 centrado">
                        <img src={clasificacion} className="img-fluid img-pYc" alt="clasificacion ABC" />
                    </div>

                    <div className="col-sm-4 tamanio izquierda abajo-1">
                        <h1>Clasificación ABC</h1>
                    </div>

                </div>





                <div className="row justify-content-center arriba">

                    <div className="col-sm-2 centrado">
                        <img src={modelos} className="img-fluid img-pYc" alt="modelos" />
                    </div>

                    <div className="col-sm-4 tamanio izquierda abajo">
                        <h1>Modelos en Grupo</h1>
                    </div>

                </div>


                <div className="row justify-content-center arriba">

                    <div className="col-sm-2 centrado">
                        <img src={prediccion} className="img-fluid img-pYc" alt="prediccion" />
                    </div>

                    <div className="col-sm-4 tamanio izquierda abajo">
                        <h1>Predicción de Demanda</h1>
                    </div>

                </div>


             


                <div id="trial" className="row justify-content-center arriba" >
                    <br />

                </div>


                <div className="row justify-content-center arriba">
                    <div className="col-sm centrar sexta">


                        <h1>Solicita un trial de 30 días</h1>

                        <h2 className="arriba"> Déjanos tus datos y  activaremos tu demo </h2>

                        <Form />

                    </div>

                </div>


                <div className="row justify-content-end">
                    <div className="col-sm-5 derecha">
                        <img src={logoMini} className="img-fluid " />
                    </div>
                </div>

            </div>


            <div className="container-fluid arriba">
            <div  id="contacto" className="row justify-content-center arriba" >
                    <br/>
                
                </div> 


                <div className="row justify-content-center arriba foot">
                    <div className="col-sm-4 centrar espacio">
                        <img src={logoBlanco} alt="scandere" className='img-fluid imagen-logo' />
                        <ol className='lista'>
                        <li><a href="/#tecnologia">Tecnología</a></li>
                            <li><a href="/#optimizacion">Optimización</a></li>
                            <li><a href="/#trial">Trial 30 días</a></li>
                            <li><a href="/#contacto">Contacto</a></li>
                        </ol>

                    </div>

                    <div className="col-sm-4 centrar espacio">
                        <FormFooter />

                    </div>

                </div>

                <div className="col-sm-4 centrar espacio copy" >
                <p>© 2023 by Scandere. Powered and secured by Scandere</p>
                </div>
            </div>
            



        </>





    );
}

export default Home;
