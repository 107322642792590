import React from "react";
import AOS from 'aos';
import { useEffect } from "react"
import { useLocation } from "react-router-dom";
import imagen from "../../Img/Admin.png"

import Input from '../../Componentes/FormElements/Input';
import Button from '../../Componentes/FormElements/Button';
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_EMAIL,
    VALIDATOR_MIN
} from '../../Componentes/util/validators';

import ReCAPTCHA from 'react-google-recaptcha';
import { useReducer, useCallback, useState, useRef } from "react";
//import emailService from '../../servicios/email';
import Swal from 'sweetalert2'
import axios from "axios";


const formReducer = (state, action) => {
    switch (action.type) {
        case 'INPUT_CHANGE':
            let formIsValid = true;
            for (const inputId in state.inputs) {
                if (inputId === action.inputId) {
                    formIsValid = formIsValid && action.isValid;
                } else {
                    formIsValid = formIsValid && state.inputs[inputId].isValid;
                }
            }
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.inputId]: { value: action.value, isValid: action.isValid }
                },
                isValid: formIsValid
            };
        default:
            return state;
    }
};

function Form() {


    useEffect(() => {
        AOS.init()
    }, [])

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, [location]);



    const [captachValido, cambiarCaptchaValido] = useState(null);
    const [usuarioValido, cambiarUsuarioValido] = useState(false);
    const [envio, setEnvio] = useState(false);
    const [habilitar, setHabilitar] = useState(false);

    const [formState, dispatch] = useReducer(formReducer, {
        inputs: {
            Nombre: {
                value: '',
                isValid: false
            },
            Correo: {
                value: '',
                isValid: false
            },
            Telefono: {
                value: '',
                isValid: false
            },
            Compania: {
                value: '',
                isValid: false
            },
           /* Direccion: {
                value: '',
                isValid: false
            },*/
        },
        isValid: false
    });

    const inputHandler = useCallback((id, value, isValid) => {
        dispatch({
            type: 'INPUT_CHANGE',
            value: value,
            isValid: isValid,
            inputId: id
        });
    }, []);



    const captcha = useRef(null);

    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            cambiarCaptchaValido(true);
        }
    }

    const placeSubmitHandler = async (e)=>{
        e.preventDefault();
        //console.log(formState.inputs); // send this to the backend!

        const newEmail = {
            nombre: formState.inputs.Nombre.value,
            correo: formState.inputs.Correo.value,
            compañia: formState.inputs.Compania.value,
            telefono: formState.inputs.Telefono.value,
            //direccion: formState.inputs.Direccion.value
        }


        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            cambiarUsuarioValido(true);
            cambiarCaptchaValido(true);


            
            if ((formState.inputs.Nombre.isValid &&
                formState.inputs.Correo.isValid &&
                formState.inputs.Compania.isValid) && 
                
                ((formState.inputs.Telefono.value.toString().length===0 && !formState.inputs.Telefono.isValid)
                ||
                (formState.inputs.Telefono.value.toString().length >0 && formState.inputs.Telefono.isValid))
                
                && captachValido
                
                ) {


                    

                    const  res = await axios.post(process.env.REACT_APP_BACKEND_URL, newEmail);
                    setEnvio(true)
                    setHabilitar(true)
                    
                 
                    
                    
                

                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })

                setTimeout(() => {
                                setEnvio(false);
                                setHabilitar(false)
                           }, 8000);

                
                           

            }else{
                Swal.fire({
                    icon: 'error',
                    title: "Llene todo los campos",
                    showConfirmButton: false,
                    timer: 3000
                })
            }

        } else {

            console.log("Por favor acepta el captcha");
            cambiarUsuarioValido(false);
            cambiarCaptchaValido(false);
            setEnvio(false)
        }
    }



    return (

        <div className="container">

            <div id="prueba" className="row justify-content-center arriba">
                <div className="col-sm-8 centrado" data-aos="fade-right">

                   
                    <div className="row justify-content-center ayuda-centrado ayuda-arriba">

    
                        <div className="col-sm-10">

                            <form onSubmit={placeSubmitHandler}>
                                <Input
                                    id="Nombre"
                                    placeholder="Nombre"
                                    element="input"
                                    type="text"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Coloque un nombre"
                                    onInput={inputHandler}

                                />
                                <Input
                                    id="Correo"
                                    element="input"
                                    placeholder="Email"
                                    validators={[VALIDATOR_EMAIL()]}
                                    errorText="Coloque un Email"
                                    onInput={inputHandler}
                                />

                                <Input
                                    id="Telefono"
                                    element="input"
                                    placeholder="Telefono, no es obligatorio"
                                    validators={[VALIDATOR_MIN(10)]}
                                    errorText="Coloque un número de teléfono, no es obligatorio"
                                    onInput={inputHandler}
                                    type="number"
                                />

                                <Input
                                    id="Compania"
                                    placeholder="Compañia"
                                    element="input"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Coloque su compañia"
                                    onInput={inputHandler}
                                />

                                

                                




                                <div className='captcha'>

                                    <div>
                                        <ReCAPTCHA
                                            ref={captcha}
                                            sitekey='6LfpFm4jAAAAAAgTzpLIie2F4kl08kEdpY98Mana'
                                            onChange={onChange}
                                        />
                                    </div>


                                    <div className='izquierda'>
                                        <Button type="submit" disabled={habilitar}>
                                            Enviar
                                        </Button>
                                    </div>

                                    {captachValido === false && <div className='error-captcha'> Por favor acepta el captcha</div>}
                                    {envio === true && <div className='error-captcha'> Te contactaremos para activar tu trial de 30 días</div>}
                                    

                                </div>




                            </form>



                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Form;
