import logo from './logo.svg';
import './App.css';
import Header from './Componentes/Header/Header';
import React from 'react';
import Home from './Pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <main>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<div>PAGE NOT FOUND</div>} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
