import React, { useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Container,
  LogoContainer,
  Wrapper,
  Menu,
  MenuItem,
  MenuItemLink,
  MobileIcon,
} from "./Elements";
import {
  FaBattleNet,
  FaBars,
  FaTimes,
  FaHome,
  FaUserAlt,
  FaBriefcase,
  FaGlasses,
} from "react-icons/fa";
import { IconContext } from "react-icons";

import logo from "../../Img/logo.png"
import "./Header.css"


function Header() {
  const navRef = useRef();



  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <div className="sticky-top" >
      <Container>
        <Wrapper>
          <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
            <LogoContainer>
              <a href="/">
                <img src={logo} width="200rem" height="40rem" />
              </a>
            </LogoContainer>

            <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
              {showMobileMenu ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <Menu open={showMobileMenu}>
              <div>
                <a href="/#home">
                  <MenuItem>
                    <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                      <a href="/#home" className="fuente_principal"> Home </a>
                    </MenuItemLink>
                  </MenuItem>
                </a>
              </div>
              <div>
                <a href="/#tecnologia">
              <MenuItem>
                <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                    <a className="fuente_principal" href="/#tecnologia">Tecnología</a>
                </MenuItemLink>
              </MenuItem>
              </a>
              </div>

              <div>
                <a href="/#optimizacion">
              <MenuItem>
                <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                  
                    <a href="/#optimizacion" className="fuente_principal">Optimización</a>

                </MenuItemLink>
              </MenuItem>
              </a>
              </div>

              <div>
                <a href="/#trial">
              <MenuItem>
                <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                  
                    <a href="/#trial" className="fuente_principal">Trial 30 días</a>
                  
                </MenuItemLink>
              </MenuItem>
              </a>
              </div>
              <div>
                <a  href="/#contacto">
              <MenuItem>
                <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                 
                    <a href="/#contacto" className="fuente_principal">Contacto</a>
                  
                </MenuItemLink>
              </MenuItem>
              </a>
              </div>


            </Menu>
          </IconContext.Provider>
        </Wrapper>
      </Container>
    </div>
  );
};



export default Header;